import React, { useState, useEffect } from 'react';
import { Box, Flex, Image, Text, HStack } from '@chakra-ui/react';

const Carousel = () => {
  const arrowStyles = {
    cursor: 'pointer',
    pos: 'absolute',
    top: '50%',
    w: 'auto',
    mt: '-22px',
    p: '16px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    transition: '0.6s ease',
    borderRadius: '0 3px 3px 0',
    userSelect: 'none',
    _hover: {
      opacity: 0.8,
      bg: 'black',
    },
  };

  const slides = [
    { img: '/1.png' },
    { img: '/2.png' },
    { img: '/3.png' },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesCount = slides.length;
  const timeoutRef = React.useRef(null);

  const prevSlide = () => {
    setCurrentSlide(s => (s === 0 ? slidesCount - 1 : s - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((prevCurrentSlide) =>
      prevCurrentSlide === slidesCount - 1 ? 0 : prevCurrentSlide + 1
    );
  };
    // Effect to slide automatically every 5 seconds
    useEffect(() => {
      timeoutRef.current = setTimeout(nextSlide, 5000); // Change slide every 5 seconds
  
      // Clean up the interval on unmount
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, [currentSlide]);

  const setSlide = (slide) => {
    setCurrentSlide(slide);
  };

  const carouselStyle = {
    transition: 'all .5s',
    ml: `-${currentSlide * 100}%`,
  };

  return (
    <Flex
      w="full"
      bg="#edf3f8"
      _dark={{
        bg: '#3e3e3e',
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Flex w="full" overflow="hidden" pos="relative">
        <Flex h="400px" w="full" {...carouselStyle}>
          {slides.map((slide, sid) => (
            <Box key={`slide-${sid}`} w="full" h="400px" shadow="md" flex="none" position="relative">
              <Image
                src={slide.img}
                alt="carousel image"
                w="full"
                h="full"
                objectFit="cover" // Ensure images cover the area without stretching
              />
              <Text
                color="white"
                fontSize="xs"
                p="8px 12px"
                pos="absolute"
                top="0"
                zIndex="1" // Ensure the text is above the image
              >
                {sid + 1} / {slidesCount}
              </Text>
            </Box>
          ))}
        </Flex>
        <Text {...arrowStyles} left="0" onClick={prevSlide}>
          &#10094;
        </Text>
        <Text {...arrowStyles} right="0" onClick={nextSlide}>
          &#10095;
        </Text>
        <HStack justify="center" pos="absolute" bottom="8px" w="full">
          {Array.from({ length: slidesCount }).map((_, slide) => (
            <Box
              key={`dots-${slide}`}
              cursor="pointer"
              boxSize={["7px", "15px"]}
              m="0 2px"
              bg={currentSlide === slide ? "blackAlpha.800" : "blackAlpha.500"}
              rounded="50%"
              display="inline-block"
              transition="background-color 0.6s ease"
              _hover={{ bg: "blackAlpha.800" }}
              onClick={() => setSlide(slide)}
            />
          ))}
        </HStack>
      </Flex>
    </Flex>
  );
  
};

export default Carousel;
