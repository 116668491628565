import React from 'react';
import { ChakraProvider, Divider } from '@chakra-ui/react';
import customTheme from './themes/themes';
import NavBar from './components/Navbar';
import HomeHero from './components/HomeHero';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import RefForm from './components/RefForm';
import GoogleForm from './components/GoogleForm';
function App() {
  return (
    <Router>
      <ChakraProvider theme={customTheme}>
        <NavBar /> 
        <Routes>
          <Route path="/referral" element={<GoogleForm />} />
          <Route path="/college" element={<HomeHero />} />
          <Route path="/ambassador" element={<HomeHero />} />
          <Route path="/fanclub" element={<HomeHero />} />
          <Route path="/" element={<HomeHero />} />
        </Routes>
        <Divider borderColor="white" borderWidth="1px" />
        <Footer />
      </ChakraProvider>
    </Router>
  );
}

export default App;
