import React from 'react';
import { Flex, Button, chakra, Box } from '@chakra-ui/react';

const AmbassadorContent = ({ onOpen }) => {
  return (
    <Flex
      direction="column"
      alignItems="center" // Center the items horizontally
      justifyContent="center"
      px={{ base: 4, md: 8, lg: 20 }}
      py={24}
      zIndex={3}
    >
      {/* Constrain the content in a 250x250 box */}
      <Box textAlign="center" size="250px" m="auto">
        <chakra.span
          color="brand.600"
          _dark={{ color: "gray.300" }}
          fontSize="lg"
          textTransform="uppercase"
          fontWeight="extrabold"
        >
          Illicit Elixir Ambassador Program
        </chakra.span>
        <chakra.h1
          mb={4}
          fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
          fontWeight="bold"
          color="brand.600"
          _dark={{ color: "gray.300" }}
          lineHeight="shorter"
          textShadow="2px 0 currentcolor"
        >
          Let’s build this Community together
        </chakra.h1>
        <chakra.p
          mb={4}
          fontSize="lg"
          color="brand.600"
          _dark={{ color: "gray.400" }}
          letterSpacing="wider"
        >
          Earn special access, incentives, and rewards by referring your friends and spreading the word
        </chakra.p>
        <Button
          mt={2}
          px={5}
          py={3}
          fontWeight="bold"
          rounded="md"
          bg="white"
          color="black"
          _hover={{ bg: "gray.200" }}
          onClick={onOpen} // Attach the onOpen function to the button's onClick event
        >
          Join our Ambassador Program
        </Button>
      </Box>
    </Flex>
  );
};

export default AmbassadorContent;
