/* global google */
import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode';
import { Box, useToast, Text, Spinner, Button } from '@chakra-ui/react';
import axios from 'axios';
import QRCode from 'qrcode.react';

const isMobile = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const GoogleForm = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [showQRCode, setShowQRCode] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const passId = searchParams.get('passId');

    const handleCredentialResponse = async (response) => {
      setIsLoading(true);
      try {
        const decodedToken = jwtDecode(response.credential);
        const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
    
        const issueWalletData = {
          brandId: 9,
          programId: 570,
          templateId: 400,
          templateTierId: 233,
          person: {
            firstName: decodedToken.given_name,
            lastName: decodedToken.family_name,
            email: decodedToken.email,
          },
          passdata: {
            metaData: {
              referrals: 0, // Replace with actual referral data if available
            },
          },
        };
    
        const walletResponse = await axios.post(`${baseURL}/issue-wallet`, issueWalletData);
    
        if (walletResponse.data && walletResponse.data.downloadUrl) {
          const fullUrl = `https://wallet-pass-sandbox.bambumeta.software${walletResponse.data.downloadUrl}`;
    
          // If on mobile, redirect to the full URL
          if (isMobile()) {
            window.location.href = fullUrl;
          } else {
            // If on desktop, prepare to show the QR code
            setQrCodeUrl(fullUrl);
            setShowQRCode(true);
          }
    
          // If passId is present, call the /increment-referral API
          if (passId) {
            const zapierWebhookUrl = `https://hooks.zapier.com/hooks/catch/9822474/3a95efk/?passId=${passId}`;
            await axios.post(zapierWebhookUrl);
            // You can handle the response or add additional logic here if needed
          }
        } else {
          toast({
            title: 'Error',
            description: 'No download URL received',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: 'An error occurred',
          description: error.response ? error.response.data : 'No response data',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
    

    const initializeGoogleSignIn = () => {
      if (document.getElementById('google-signin-btn')) {
        google.accounts.id.initialize({
          client_id: '886122045832-2j1aes3naf824rs6a4o2gg78r5hne2r8.apps.googleusercontent.com',
          callback: handleCredentialResponse
        });

        google.accounts.id.renderButton(
          document.getElementById('google-signin-btn'),
          { theme: 'outline', size: 'large' }
        );

        google.accounts.id.prompt();
      }
    };

    const loadGoogleSignInScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    };

    if (!window.google) {
      loadGoogleSignInScript();
    } else {
      initializeGoogleSignIn();
    }
  }, []);

  return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={{ base: '10px', md: '20px' }}
        px={{ base: '4', md: '8' }}
      >
        {isLoading ? (
          <Spinner size="xl" />
        ) : (
          <>
            {!showQRCode && (
              <Button
                id="google-signin-btn"
                size="lg"
                width={{ base: 'full', sm: 'auto' }}
                height="48px"
                mt="4"
              >
                Sign in with Google
              </Button>
            )}
            {showQRCode && qrCodeUrl && (
              <>
               <Text 
                  fontSize={{ base: 'md', md: 'lg' }} 
                  mb={4} 
                  sx={{ color: 'blue.500' }} // Using Styled-System's sx prop
                >
                  Scan the QR code to get your ambassador card
                </Text>
                <QRCode value={qrCodeUrl} size={isMobile() ? 128 : 150} />
                <Box height="32px" /> {/* Use Box for spacing instead of mb */}
              </>
            )}
          </>
        )}
      </Box>
    );
  };

export default GoogleForm;
