import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  colors: {
    brand: {
      50: '#f5fee5',
      100: '#e1fbb2',
      // ...
      900: '#335500',
    },
  },
  customBlue: {
    50: '#e6f6ff',  // Lighter shade of blue
    100: '#bae3ff', // Light blue
    500: '#0095ff', // Base blue color
    700: '#006fbb', // Darker blue
    900: '#003c66', // Darkest blue
  },
  fonts: {
    heading: 'Rodger-Bold, sans-serif',
    body: 'Rodger-Bold, sans-serif',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold', // Normally, it's "semibold"
      },
      defaultProps: {
        size: 'lg', // Default size for `Button`
        variant: 'solid', // Default variant for `Button`
      },
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '32px',
        },
      },
      variants: {
        brand: {
          bg: 'brand.500',
          color: 'white',
          _hover: {
            bg: 'brand.600',
          },
        },
      },
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: 'black', // default background color
        color: 'white', // default font color
      },
    },
  },
});

export default customTheme;
