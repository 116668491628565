import React from 'react';
import {
  chakra, Flex, Box, useDisclosure, IconButton, VStack, CloseButton, Image, HStack,
  Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import GoogleForm from './GoogleForm';

const NavBar = () => {
  const mobileNav = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <chakra.header bg="black" w="full" px={{ base: 2, sm: 4 }} py={4} color="white">
      <Flex alignItems="center" justifyContent="space-between" mx="auto">
        <Box>
          <Link to="/" title="Home Page" display="flex" alignItems="center">
            <Image
              src="/ie-logo03.svg"
              alt="Illicit Elixirs Logo"
              width={{ base: "100px", md: "150px", lg: "200px" }}
              height="auto"
            />
          </Link>
        </Box>
        <HStack display={{ base: 'none', md: 'flex' }} spacing={4}>
          <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Home</Link>
          <Link to="/about" style={{ textDecoration: 'none', color: 'white' }}>About</Link>
          <Button colorScheme="blue.500" onClick={onOpen}>
            Refer a Friend
          </Button>
        </HStack>

        <IconButton
          size="md"
          icon={mobileNav.isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: 'none' }}
          onClick={mobileNav.onToggle}
        />
      </Flex>

      <VStack
        pos="absolute" top={0} left={0} right={0}
        display={mobileNav.isOpen ? "flex" : "none"}
        flexDirection="column" p={2} pb={4} m={2}
        bg="black" spacing={3} rounded="sm" shadow="sm"
        zIndex={20}  // Increased z-index
      >
        <CloseButton aria-label="Close menu" onClick={mobileNav.onClose} />
        <Button as={Link} to="/" w="full" variant="ghost" onClick={mobileNav.onClose}>Home</Button>
        <Button as={Link} to="/about" w="full" variant="ghost" onClick={mobileNav.onClose}>About</Button>
        <Button onClick={() => { onOpen(); mobileNav.onClose(); }} colorScheme="green" w="full">Referral</Button>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader color="customBlue.500">Refer a Friend</ModalHeader>
    <ModalCloseButton color="green" />
    <ModalBody>
      <GoogleForm onClose={onClose} />
    </ModalBody>
  </ModalContent>
</Modal>
    </chakra.header>
  );
};

export default NavBar;
