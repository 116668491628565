import React from 'react';
import { Flex, chakra, Icon, Link } from '@chakra-ui/react';
import { FaRedditAlien, FaFacebookF, FaGithub } from 'react-icons/fa';

const Footer = () => {
  return (
    <Flex
      as="footer"
      w="full"
      bg="black"
      color="white"
      alignItems="center"
      justifyContent="center"
      py={4}
    >
      <Flex
        w="full"
        flexDir={{
          base: "column",
          sm: "row",
        }}
        align="center"
        justify="space-between"
        px={{ base: 4, sm: 6 }}
        py="4"
      >
        <Link
          href="#"
          fontSize="xl"
          fontWeight="bold"
          _hover={{
            color: "gray.500",
          }}
        >
          Bambumeta Demo
        </Link>

        <chakra.p
          py={{
            base: "2",
            sm: "0",
          }}
        >
          © {new Date().getFullYear()} All rights reserved
        </chakra.p>

        <Flex>
          <Link href="#" mx="2" aria-label="Reddit" isExternal>
            <Icon as={FaRedditAlien} boxSize="5" />
          </Link>

          <Link href="#" mx="2" aria-label="Facebook" isExternal>
            <Icon as={FaFacebookF} boxSize="5" />
          </Link>

          <Link href="#" mx="2" aria-label="Github" isExternal>
            <Icon as={FaGithub} boxSize="5" />
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
