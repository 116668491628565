import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box, Flex, Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalCloseButton, ModalBody, useDisclosure
} from '@chakra-ui/react';
import Carousel from './Carousel';
import ActivationForm from './ActivationForm';
import FanClubActivationForm  from './FanClubActivationForm';
import AmbassadorActivationForm from './AmbassadorActivationForm';
import CollegeContent from './CollegeContent';
import AmbassadorContent from './AmbassadorContent';
import FanClubContent from './FanClubContent';

const HomeHero = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeForm, setActiveForm] = useState('default');
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/college':
        setActiveForm('college');
        break;
      case '/ambassador':
        setActiveForm('ambassador');
        break;
      case '/fanclub':
        setActiveForm('fanclub');
        break;
      default:
        setActiveForm('default');
    }
  }, [location.pathname]);

  const renderContent = () => {
    switch (activeForm) {
      case 'college':
        return <CollegeContent onOpen={onOpen} />;
      case 'ambassador':
        return <AmbassadorContent onOpen={onOpen} />;
      case 'fanclub':
        return <FanClubContent onOpen={onOpen} />;
      default:
        return <CollegeContent onOpen={onOpen} />;
    }
  };

  const renderModalContent = () => {
    switch (activeForm) {
      case 'college':
        return <ActivationForm onClose={onClose} />;
      case 'ambassador':
        return <AmbassadorActivationForm onClose={onClose} />;
      case 'fanclub':
        return <FanClubActivationForm onClose={onClose} />;
      default:
        return <ActivationForm onClose={onClose} />;
    }
  };

  return (
    <Box position="relative" w="full">
      <Carousel />
      <Flex
        direction="column"
        position="absolute"
        top="0"
        left="0"
        right="0"
        p={{ base: 4, md: 8 }}
        align={{ base: "center", md: "start" }}
        w="full"
        h="full"
        justify="center"
        bg="blackAlpha.600"
      >
        {renderContent()}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.700" />
        <ModalContent bg="#1A202C" mx={{ base: 4, lg: 0 }} maxW="80vw">
          <ModalHeader>Join our Program</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderModalContent()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default HomeHero;