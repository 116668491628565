import React, { useState } from 'react';
import {
  Box,
  chakra,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Flex,
  Text
} from '@chakra-ui/react';
import axios from 'axios';
import QRCode from 'qrcode.react';


const ActivationForm = ({ onClose }) => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    person: {
      firstName: '',
      lastName: '',
      email: '',
    },
    passdata: {
      metaData: {
        referrals: '0',
        college: '',
      },
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showQRCode, setShowQRCode] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const handleClose = () => {
    setShowQRCode(false); // This will hide the QR code
    // Any other logic that needs to run when the modal closes
  };
  // Check if the device is mobile
  const isMobile = () => {
    return (
      (typeof window.orientation !== "undefined") ||
      (navigator.userAgent.indexOf('IEMobile') !== -1)
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'firstName' || name === 'lastName' || name === 'email') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        person: {
          ...prevFormData.person,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        passdata: {
          ...prevFormData.passdata,
          metaData: {
            ...prevFormData.passdata.metaData,
            [name]: value,
          },
        },
      }));
    }
  };

  const validateFields = () => {
    let errors = {};
  
    if (!formData.person.firstName.trim()) {
      errors.firstName = "First name is required";
    }
  
    if (!formData.person.lastName.trim()) {
      errors.lastName = "Last name is required";
    }
  
    if (!formData.person.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.person.email)) {
      errors.email = "Email is invalid";
    }
  
    if (!formData.passdata.metaData.college.trim()) {
      errors.college = "College is required";
    }
  
    return errors;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFields();

    if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
    }

    setIsLoading(true);

    try {
        const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

        const issueWalletData = {
            brandId: 9,
            programId: 570,
            templateId: 400,
            templateTierId: 233,
            person: {
                firstName: formData.person.firstName,
                lastName: formData.person.lastName,
                email: formData.person.email,
            },
            passdata: {
                metaData: {
                    referrals: formData.passdata.metaData.referrals || 0,
                    college: formData.passdata.metaData.college,
                },
            },
        };

        const walletResponse = await axios.post(`${baseURL}/issue-wallet`, issueWalletData);

        if (walletResponse.data && walletResponse.data.downloadUrl) {
            const fullUrl = `https://wallet-pass-sandbox.bambumeta.software${walletResponse.data.downloadUrl}`;
            
            if (isMobile()) {
                window.location.href = fullUrl;
            } else {
                setQrCodeUrl(fullUrl);
                setShowQRCode(true);
            }
        } else {
            toast({
                title: 'Error',
                description: 'No download URL received',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    } catch (error) {
        toast({
            title: 'An error occurred',
            description: error.response ? error.response.data : 'No response data',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    } finally {
        setIsLoading(false);
    }
};

  

  return (
    <Box
      bg="#edf3f8"
      _dark={{
        bg: "#111",
      }}
      p={10}
      maxW="3xl"
      mx="auto"
      w="full"
    >
      {showQRCode ? (
        // Conditionally render the QR code section
        <Box textAlign="center" p={6} bg="white" _dark={{ bg: "gray.800" }} rounded="md" shadow="base">
        <Box p={4} display="inline-block" bg="gray.50" _dark={{ bg: "gray.700" }} borderRadius="md">
          {/* The QRCode component from 'qrcode.react' */}
          <QRCode value={qrCodeUrl} size={256} />
        </Box>
        <Text mt={4} mb={6} color="blue.500"> Scan the QR code to download your wallet</Text>
        <Button colorScheme="blue" onClick={handleClose}>
          Close
        </Button>
      </Box>

      ) : (
        <chakra.form
        shadow="base"
        rounded="md"
        overflow="hidden"
        p={{ base: 4, md: 8 }}
        bg="white"
        color="black" // Sets the text color to black
        _dark={{
          bg: "gray.800",
          color: "white", // Text color for dark mode
        }}
        onSubmit={handleSubmit}
      >
          <Stack spacing={6}>
            <FormControl isInvalid={errors.firstName}>
              <FormLabel htmlFor="firstName">First Name</FormLabel>
              <Input
                id="firstName"
                name="firstName"
                onChange={handleChange}
                value={formData.person.firstName}
                color="black" // Sets the input text color to black
                _dark={{
                  color: "white", // Input text color for dark mode
                }}
              />
            </FormControl>
  
            <FormControl isInvalid={errors.lastName}>
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <Input
                id="lastName"
                name="lastName"
                onChange={handleChange}
                value={formData.person.lastName}
                placeholder="Enter your last name"
                color="black" // Sets the input text color to black
                _dark={{
                  color: "white", // Input text color for dark mode
                }}
              />
            </FormControl>
  
            <FormControl isInvalid={errors.email}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={formData.person.email}
                placeholder="Enter your email"
                color="black" // Sets the input text color to black
                _dark={{
                  color: "white", // Input text color for dark mode
                }}
              />
            </FormControl>
  
            <FormControl isInvalid={errors.college}>
              <FormLabel htmlFor="college">College</FormLabel>
              <Input
                id="college"
                name="college"
                onChange={handleChange}
                value={formData.passdata.metaData.college}
                placeholder="Enter your college name"
                color="black" // Sets the input text color to black
                _dark={{
                  color: "white", // Input text color for dark mode
                }}
              />
            </FormControl>
          </Stack>
  
          <Flex justify="space-between" mt={4}>
            <Button colorScheme="gray" onClick={onClose}>
              Close
            </Button>
            <Button 
              colorScheme="blue"
              type="submit" 
              isLoading={isLoading}
            >
              Submit
            </Button>
          </Flex>
        </chakra.form>
      )}
    </Box>
  );  
};

export default ActivationForm;
